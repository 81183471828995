/** @jsxImportSource @emotion/react */
import React, {Component} from 'react'
import {connect} from 'react-redux'
import classNames from 'classnames'
import { jsx, css } from '@emotion/react'
import { padEnd, size } from 'lodash'
import { getActiveLanguageCode } from './LanguageFlagSelector'

class CurrencyValue extends Component {

    render() {
        const { value, align_right, align_center, prefix, enable_inline_spacing, enable_inline_spacing_right, use_span, variant, className, extraCss, showCents } = this.props

        var formatter = new Intl.NumberFormat(getActiveLanguageCode(), {
            style: 'decimal',
            minimumFractionDigits: showCents !== false ? 2 : 0,
            maximumFractionDigits: showCents !== false ? 2 : 0,
            // style: 'currency',
            // currency: 'ZAR',
            // currencyDisplay: 'symbol',
        })

        const sign = value < 0 ? "-" : ""
        if ( ! variant || variant === "flat" ) {
            const formatted_currency = formatter.format(Math.abs(value) || 0)

            const content = [ enable_inline_spacing === true && <span key="cv1">&nbsp;</span>,
                              <span key="cv2">{ prefix || ""}{sign}€{formatted_currency}</span>,
                              (enable_inline_spacing === true || enable_inline_spacing_right === true) && <span key="cv3">&nbsp;</span>
                            ]
            if ( use_span === true ) {
                return (
                    <span className={className} css={extraCss}>
                      {content}
                    </span>
                )
            } else {
                return (
                    <div css={[align_right === true ? align_right_style : null,
			      extraCss]}>
                      {content}
                    </div>
                )
            }
        } else if ( variant === "multiheight" ) {

            // const units = Math.floor(Math.abs(value))
            const parts = `${Math.abs(value)}`.split(".")
            const units = parts[0]
            let cents = "0"
            if (size(parts) > 1) {
                cents = parts[1]
            }
            cents = padEnd(cents, 2, "0").slice(0, 2)

            return (
                <div css={[multiheight_currency_value_style,
                           align_right === true ? align_right_style : null,
                           align_center === true ? align_center_style : null]}>
                  { enable_inline_spacing === true && <span>&nbsp;</span> }
                  <div css={multiheight_cents_style}>
                    {prefix || ""}{sign}€
                  </div>
                  <div css={multiheight_units_style}>
                    {units}
                  </div>
                  <div css={multiheight_cents_style}>
                    {cents}
                  </div>
                  { enable_inline_spacing === true && <span>&nbsp;</span> }
                </div>
            )
        }
    }
}

function mapStateToProps(state, props) {
    const { value, prefix, align_right } = props
    return {
        value: value,
        align_right,
        prefix
    }
}

export default connect(mapStateToProps)(CurrencyValue)

const multiheight_currency_value_style = css`
display: flex;
line-height: 64px;
`

const align_right_style = css`
text-align: right;
`

const align_center_style = css`
justify-content: center;
`

const multiheight_cents_style = css`
font-size: 20px;
line-height: 40px;
`
 
const multiheight_units_style = css`
font-size: 55px;
`

